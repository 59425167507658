<script>
  import { twMerge } from 'tailwind-merge'
  import { colors, baseSize, sizes, baseStyle } from './base-settings.js'

  export let size = baseSize
  export let role = 'img'
  export let color = 'currentColor'

  export let ariaLabel = 'plus'

  $: fillColor = colors[color] || color
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  fill={fillColor}
  {...$$restProps}
  class={twMerge(baseStyle, sizes[size ?? 'md'], $$props.class)}
  {role}
  aria-label={ariaLabel}
  tabindex="-1"
  viewBox="0 0 40 40"
  on:click
  on:keydown
  on:keyup
  on:focus
  on:blur
  on:mouseenter
  on:mouseleave
  on:mouseover
  on:mouseout
>
  <rect
    class="cls-1"
    x="9.91"
    y="17.94"
    width="20.18"
    height="3.92"
    rx="0.41"
    ry="0.41"
  /><rect
    class="cls-1"
    x="18.04"
    y="10.69"
    width="3.92"
    height="18.63"
    rx="0.41"
    ry="0.41"
  />
</svg>
